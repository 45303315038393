import { Component, Vue } from 'vue-property-decorator'

//global
import { Variables } from '@/config/variables';

//esic components
import EContent from '@/components/esic/eContent/EContent.vue';

//class
import { Alert } from '@/services/notification';

//molds
import { Form, Option } from '@/components/wrappers/molds/Form';

//wrappers
import {
	WForm,
	WLine,
	wIcon,
	Loader,
	WImage,
	WButton,
	WColumn,
	SizedBox,
	WColumns,
	SmoothDropdown,
} from '@/components/wrappers';

@Component({
	components:{
		WForm,
		WLine,
		wIcon,
		Loader,
		WImage,
		WColumn,
		WButton,
		WColumns,
		SizedBox,
		EContent,
		SmoothDropdown,
	}
})
export default class RegisterPage extends Vue {
	alert = new Alert(this.$store) 

	textLaw = Variables.htmlTextLaw

	dispatchForm(dataForm: any): void {
		const send = {
			template:"esic",
			details: dataForm.details,
			moreDetails: dataForm
		}

		delete send.moreDetails.details

		this.$store.dispatch("process/createProcess", send).then(() => {
			const err = this.$store.getters["process/error"]
			if(err){
				this.alert.apiMessage("Não foi possível enviar o pedido", err, "danger")
			}else{
				this.alert.success("Pedido enviado com sucesso")
				this.$router.replace("/")
			}
		})
	}

	get isLoader(): boolean {
		return this.$store.getters["process/loader"]
	}

	get form(): Form {
		return {
			flex: false,
			indent: true,
			grid: [
				[
					{
						fieldName: "email",
						type: "e-mail",
						label: "E-mail",
						maxlength: 100,
					},
					// {
					// 	fieldName: "confEmail",
					// 	type: "email",
					// 	label: "Confirmação do e-mail *",
					// 	maxlength: 100,
					// 	required: false
					// },
				],[
					{
						fieldName: "phone",
						type: "cel",
						label: "Telefone de contato (celular)",
						maxlength: 11,
					},
					{
						fieldName: "phone2",
						type: "cel",
						label: "Outro Telefone",
						maxlength: 11,
					},
				],[
					{
						fieldName: "street",
						type: "text",
						label: "Logradouro",
						maxlength: 100,
					},
					{
						fieldName: "number",
						type: "number",
						label: "Número",
						maxlength: 10,
					},
					{
						fieldName: "complement",
						type: "text",
						label: "Complemento",
						maxlength: 200,
					},
					{
						fieldName: "postalCode",
						type: "postalCode",
						label: "Código Postal",
						maxlength: 9,
					},
				],[
					{
						fieldName: "district",
						type: "text",
						label: "Bairro",
						maxlength: 100,
					},
					{
						fieldName: "State",
						type: "select",
						label: "Estado",
						maxlength: 100,
						value: "ES",
						options: this.getSelectStates()
					},
					{
						fieldName: "city",
						type: "text",
						label: "Cidade",
						maxlength: 100,
					},
				],
				[
					{
						fieldName: "details",
						type: "textarea",
						label: "Descreva a solicitação no campo abaixo *",
						maxlength: 500,
						required: true
					},
				]

			]
		}
	}

	getSelectStates(): Option [] {
		return [
			{label: "Acre", value: "AC"},
			{label: "Alagoas", value: "AL"},
			{label: "Amapá", value: "AP"},
			{label: "Amazonas", value: "AM"},
			{label: "Bahia", value: "BA"},
			{label: "Ceará", value: "CE"},
			{label: "Distrito Federal", value: "DF"},
			{label: "Espírito Santo", value: "ES"},
			{label: "Goiás", value: "GO"},
			{label: "Maranhão", value: "MA"},
			{label: "Mato Grosso", value: "MT"},
			{label: "Mato Grosso do Sul", value: "MS"},
			{label: "Minas Gerais", value: "MG"},
			{label: "Pará", value: "PA"},
			{label: "Paraíba", value: "PB"},
			{label: "Paraná", value: "PR"},
			{label: "Pernambuco", value: "PE"},
			{label: "Piauí", value: "PI"},
			{label: "Rio de Janeiro", value: "RJ"},
			{label: "Rio Grande do Norte", value: "RN"},
			{label: "Rio Grande do Sul", value: "RS"},
			{label: "Rondônia", value: "RO"},
			{label: "Roraima", value: "RR"},
			{label: "Santa Catarina", value: "SC"},
			{label: "São Paulo", value: "SP"},
			{label: "Sergipe", value: "SE"},
			{label: "Tocantins", value: "TO"}
		]
	}
}
